import { DataLayer } from "~/dataLayer";

export const isDev = process.env.NODE_ENV === "development";

export interface Config {
  apiUrl: string;
  apiKey: string;
}

export function getConfig(): Config {
  return {
    apiUrl: process.env.KONBERT_API_URL ?? "http://localhost:4000/api/v1",
    apiKey: process.env.KONBERT_API_KEY ?? "anonymous",
  };
}

export function createDataLayer() {
  return new DataLayer("/api/v1");
}

export function createDataLayerV2() {
  return new DataLayer("/api/v2");
}
